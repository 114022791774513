import React from "react"

class SpinnerComponent extends React.Component {
    render() {
        return (
            <div className="fallback-spinner d-flex align-items-center justify-content-center m-auto vh-100">
                <div className="loader"></div>
            </div>
        )
    }
}

export default SpinnerComponent
